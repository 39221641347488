import React, { useRef } from 'react';
import VideoSettings from '../VideoSettings/index.js';
import Modal from 'cccisd-modal';
import Tooltip from 'cccisd-tooltip';
import Cog from 'cccisd-icons/cog';

const ConfigureVideo = ({ row, loadData }) => {
    const videoSettingsRef = useRef();
    const closeModal = () => {
        videoSettingsRef.current.close();
    };
    return (
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Modal
                trigger={
                    <button type="button" className="btn btn-xs btn-primary">
                        <Tooltip title="Configure Video" size="medium" placement="bottom">
                            <Cog />
                        </Tooltip>
                    </button>
                }
                ref={videoSettingsRef}
                size="medium"
                title={`Video Settings - ${row['fields.label']}`}
            >
                <VideoSettings row={row} loadData={loadData} closeModal={closeModal} />
            </Modal>
        </div>
    );
};

export default ConfigureVideo;
