import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import Dropzone from 'react-dropzone';
import Axios from 'cccisd-axios';
import styles from './styles.css';

const Boilerplate = window.cccisd.boilerplate;

const Form = ({ onSuccess, clipId }) => {
    const [file, setFile] = useState(null);
    const [error, setError] = useState({});
    const [uploadProgress, setUploadProgress] = useState(null);

    function onFileDrop(accepted) {
        if (!accepted.length) return;
        setFile(accepted[0]);
        setError({ reportName: error.reportName });
    }

    async function handleSubmit() {
        const config = {
            headers: { 'Content-Type': undefined },
            onUploadProgress,
        };
        let data = new FormData();
        data.append('file', file);
        data.append('clipId', clipId);
        try {
            const response = await Axios.post(Boilerplate.route('api.producer.video.upload'), data, config);
            if (_get(response, 'data.errors')) {
                setError(response.data.errors);
            } else {
                setError({});
                setFile(null);
                onSuccess();
            }
            setUploadProgress(null);
        } catch (e) {
            console.error(e);
            setUploadProgress(null);
        }
    }

    function onUploadProgress(progressEvent) {
        setUploadProgress((progressEvent.loaded / progressEvent.total) * 100);
    }

    function showErrors() {
        const otherErrors = _omit(error, ['reportName', 'confirmChecked']);
        if (!Object.keys(otherErrors).length) {
            return null;
        }
        return (
            <div className={classnames(styles.generalErrorContainer, 'bg-danger')}>
                {Object.keys(otherErrors).map((k, i) => {
                    return (
                        <div className="text-danger" key={i}>
                            {error[k].join(', ')}
                        </div>
                    );
                })}
            </div>
        );
    }

    /* /////////////////////////////////////////////////////////////////////////
    // RENDER-RELATED /////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////// */

    if (uploadProgress) {
        return (
            <div>
                Upload Progress: <span className={styles.uploadProgress}>{Math.round(uploadProgress)}%</span>
            </div>
        );
    }

    if (file) {
        return (
            <div>
                Video file to transcode: <strong>{file.name}</strong>
                {showErrors()}
                <button type="button" className={`btn btn-primary ${styles.submit}`} onClick={handleSubmit}>
                    Upload Video
                </button>
            </div>
        );
    }

    return (
        <div>
            <Dropzone
                accept=".mp4"
                type="file"
                className={styles.dropzone}
                disablePreview
                multiple={false}
                onDrop={onFileDrop}
            >
                <p className={styles.dropzoneDescription}>
                    Drag and drop your .MP4 video file here (click to browse).
                    <br />
                    <br />
                    For files bigger than 1GB, consider upload via CyberDuck.
                </p>
            </Dropzone>
        </div>
    );
};

Form.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    clipId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default Form;
