import React from 'react';
import Modal from 'cccisd-modal';
import Tooltip from 'cccisd-tooltip';
import IconArrow from 'cccisd-icons/arrow-up6';
import Form from './Form.js';

const UploadVideo = ({ row }) => {
    return (
        <Modal
            title="Upload .mp4 video file to be transcoded"
            trigger={
                <button type="button" className="btn btn-xs btn-primary">
                    <Tooltip
                        title="Upload Raw Video for Transcoding"
                        size="medium"
                        placement="bottom"
                    >
                        <IconArrow />
                    </Tooltip>
                </button>
            }
            render={({ closeModal }) => {
                const onSuccess = () => {
                    closeModal();
                };
                return <Form clipId={row['pawn.pawnId']} onSuccess={onSuccess} />;
            }}
        />
    );
};

export default UploadVideo;
