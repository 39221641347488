import React from 'react';
import { useParams } from 'react-router-dom';
import Loader from 'cccisd-loader';
import { ProducerPlayer } from 'cccisd-video-player';
import { useVideoData } from '../../renders/Configure/CaptionSettings';
import style from './style.css';

const VideoPage = props => {
    const { clipId } = useParams();
    const { videoData } = useVideoData({ videoId: clipId });

    if (!videoData) {
        return <Loader loading />;
    }

    if (videoData.versions && videoData.versions.length === 0) {
        return (
            <div className={style.wrapper}>
                <h1 style={{ fontSize: '2em' }}>{videoData.label}</h1>
                <div>
                    <strong>
                        <em>{`Clip ID ${videoData.id}`}</em>
                    </strong>
                </div>
                <br />
                <div className="alert alert-warning">
                    No media available. Please upload media or wait for media to finish transcoding.
                </div>
            </div>
        );
    }

    return (
        <div className={style.wrapper}>
            <h1 style={{ fontSize: '2em' }}>{videoData.label}</h1>
            <div>
                <strong>
                    <em>{`Clip ID ${videoData.id}`}</em>
                </strong>
            </div>
            <div>
                <strong>
                    <em>{`Version ${videoData.latestVersion.version_number
                        .toString()
                        .padStart(3, '0')}`}</em>
                </strong>
            </div>
            <br />
            <ProducerPlayer id={clipId} />
        </div>
    );
};

export default VideoPage;
