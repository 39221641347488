import React from 'react';
import Modal from 'cccisd-modal';
import Tooltip from 'cccisd-tooltip';
import Loader from 'cccisd-loader';
import { ProducerPlayer } from 'cccisd-video-player';
import Play from 'cccisd-icons/play4';
import { useVideoData } from '../CaptionSettings';

const PreviewContents = ({ row }) => {
    const { videoData = {} } = useVideoData({ videoId: row['pawn.pawnId'] });

    if (!videoData.versions) {
        return <Loader loading />;
    }

    if (videoData.versions && videoData.versions.length === 0) {
        return (
            <strong style={{ color: 'lightgray' }}>
                No media to preview. Please upload media or wait for media to finish transcoding.
            </strong>
        );
    }

    return <ProducerPlayer id={row['pawn.pawnId']} />;
};

const PreviewModal = ({ row }) => {
    return (
        <Modal
            trigger={
                <button type="button" className="btn btn-xs btn-primary">
                    <Tooltip title="Preview Video" size="medium" placement="bottom">
                        <Play />
                    </Tooltip>
                </button>
            }
            size="large"
            title={`Preview Video - ${row['fields.label']}`}
            render={() => <PreviewContents row={row} />}
        />
    );
};

export default PreviewModal;
