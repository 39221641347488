import React from 'react';
import ConfigureVideo from './ConfigureVideo';
import ConfigureCaptions from './ConfigureCaptions';
import UploadVideo from './UploadVideo';
import PreviewVideo from './PreviewVideo';
import CopyEmbed from './CopyEmbed';
import CopyPreview from './CopyPreview';

const ConfigureColumnRender = props => {
    return (
        <div style={{ display: 'flex', gap: '0.5rem' }}>
            <ConfigureVideo {...props} />
            <UploadVideo {...props} />
            <ConfigureCaptions {...props} />
            <PreviewVideo {...props} />
            <CopyEmbed {...props} />
            <CopyPreview {...props} />
        </div>
    );
};

export default () => {
    return props => <ConfigureColumnRender {...props} />;
};
