import React from 'react';
import notification from 'cccisd-notification';
import Tooltip from 'cccisd-tooltip';
import IconShare from 'cccisd-icons/share3';

const CopyEmbed = ({ row }) => {
    return (
        <button
            type="button"
            className="btn btn-xs btn-primary"
            onClick={() => {
                navigator.clipboard.writeText('https://producer.3cisd.com/video/' + row['pawn.pawnId']);
                notification('Preview Link copied!');
            }}
        >
            <Tooltip title="Copy Preview Link" size="medium" placement="bottom">
                <IconShare />
            </Tooltip>
        </button>
    );
};

export default CopyEmbed;
