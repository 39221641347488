import React, { useState } from 'react';
import IconArrowRight from 'cccisd-icons/arrow-right20';
import IconArrowDown from 'cccisd-icons/arrow-down20';
import style from './style.css';

export default props => {
    const { title, startOpen, updateState = () => {}, type, children, context, footer } = props;

    const [isCollapsed, setCollapsed] = useState(!startOpen);

    const toggleCollapsed = () => {
        setCollapsed(prev => !prev);
        updateState(isCollapsed, type);
    };

    return (
        <div className={`panel panel-${context || 'primary'} ${style.wrapper}`}>
            <div className="panel-heading" onClick={toggleCollapsed}>
                {isCollapsed ? <IconArrowRight spaceRight /> : <IconArrowDown spaceRight />}
                <span>{title}</span>
            </div>
            {!isCollapsed && (
                <>
                    <div className="panel-body">{children}</div>
                    {footer && <div className="panel-footer">{footer}</div>}
                </>
            )}
        </div>
    );
};
