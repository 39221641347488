import _isEqual from 'lodash/isEqual';
import storage from 'store';
import md5 from 'md5';

export default function useLocalStorage({ key = 'default', defaultSettings = {} }) {
    const getHash = () => {
        let str = window.location.hostname + window.location.pathname;
        return md5(str).substr(0, 10);
    };

    const saveSettings = newSettings => {
        const hash = getHash();
        const state = storage.get(key);
        const oldSettings = state && state[hash];

        if (!_isEqual(oldSettings, newSettings)) {
            storage.set(key, {
                ...state,
                [hash]: newSettings,
            });
        }
    };

    const getSettings = () => {
        const state = storage.get(key);
        let settings = state && state[getHash()];
        if (!settings) {
            settings = defaultSettings;
        }
        return settings;
    };

    return {
        saveSettings,
        getSettings,
    };
}
