import React, { useState, useEffect } from 'react';
import _uniqBy from 'lodash/uniqBy';
import { client as apollo } from 'cccisd-apollo';
import { Manage as AppdefManage } from 'cccisd-laravel-appdefs';
import Loader from 'cccisd-loader';
import query from './query.graphql';
import useLocalStorage from './useLocalStorage.js';

function ProducerManage(props) {
    const { getSettings, saveSettings } = useLocalStorage({
        key: 'producer-manage-video-tab',
        defaultSettings: {
            project: '',
            module: '',
            lesson: '',
            videoName: '',
            clipId: '',
            requiredVariables: [],
        },
    });
    const [project, setProject] = useState(getSettings().project || '');
    const [projectList, setProjectList] = useState([]);
    const [module, setModule] = useState(getSettings().module || '');
    const [moduleList, setModuleList] = useState([]);
    const [lesson, setLesson] = useState(getSettings().lesson || '');
    const [lessonList, setLessonList] = useState([]);

    const [videoName, setVideoName] = useState(getSettings().videoName || '');
    const [clipId, setClipId] = useState(getSettings().clipId || '');

    const [autoFocus, setAutoFocus] = useState('');

    const [requiredVariables, setRequiredVariables] = useState(
        getSettings().requiredVariables || []
    );
    const [verticalNav, setVerticalNav] = useState();
    useEffect(() => {
        setVerticalNav(prev => {
            let newVerticalNav = props.verticalNav;
            newVerticalNav.find(
                nav => nav.handle === 'siteAdmin'
            ).contentConfig.tableTop.views[0].table.requiredVariables = requiredVariables;
            newVerticalNav
                .find(nav => nav.handle === 'siteAdmin')
                .contentConfig.tableTop.views[0].buttons.find(
                    b => b.componentHandle === 'Filters'
                ).settings = {
                requiredVariables,
                setRequiredVariables,
                project,
                setProject,
                projectList,
                module,
                setModule,
                moduleList,
                lesson,
                setLesson,
                lessonList,
                videoName,
                setVideoName,
                clipId,
                setClipId,
                autoFocus,
                setAutoFocus,
            };

            return [...newVerticalNav];
        });
    }, [requiredVariables, projectList, moduleList, lessonList]);

    useEffect(() => {
        getLists();
    }, [project, module]);
    async function getLists() {
        const res = await apollo.query({
            query,
            variables: {
                moduleFilter: project
                    ? { eq: { field: 'parentGroup.organization.group.label', string: project } }
                    : {},
                lessonFilter:
                    project || module
                        ? {
                              AND: [
                                  project
                                      ? {
                                            eq: {
                                                field: 'ancestorGroups.organization.group.label',
                                                string: project,
                                            },
                                        }
                                      : {},
                                  module
                                      ? {
                                            eq: {
                                                field: 'parentGroup.groupingUnit.group.label',
                                                string: module,
                                            },
                                        }
                                      : {},
                              ],
                          }
                        : {},
            },
        });
        if (res.data) {
            setProjectList(res.data.groups.organizationList);
            setModuleList(_uniqBy(res.data.groups.groupingUnitList, 'group.label'));
            setLessonList(_uniqBy(res.data.groups.siteList, 'group.label'));
        }
    }

    useEffect(() => {
        saveSettings({ project, module, lesson, videoName, clipId, requiredVariables });
    }, [project, module, lesson, videoName, clipId, requiredVariables]);

    if (!verticalNav) {
        return <Loader loading />;
    }

    return <AppdefManage {...props} {...{ verticalNav }} />;
}

export default ProducerManage;
