import React from 'react';
import PropTypes from 'prop-types';
import {
    Formik,
    Form,
    Field,
    FieldArray,
    CccisdFieldWrapper,
    CccisdInput,
    CccisdWysiwyg,
    CccisdSelect,
    CccisdCheckboxInput,
} from 'cccisd-formik';
import axios from 'cccisd-axios';
import Modal from 'cccisd-modal';
import Tooltip from 'cccisd-tooltip';
import Confirm from 'cccisd-confirm';
import notification from 'cccisd-notification';
import { ImagePicker } from 'cccisd-laravel-resources';
import HiddenBlock from 'cccisd-hidden-block';
import Loader from 'cccisd-loader';
import Upload from 'cccisd-icons/upload2';
import Plus from 'cccisd-icons/plus3';
import Cross from 'cccisd-icons/cross3';
import Play from 'cccisd-icons/play4';
import Pencil from 'cccisd-icons/pencil7';
import CheckMark from 'cccisd-icons/checkmark';
import Refresh from 'cccisd-icons/spinner11';
import Audio from 'cccisd-icons/volume-medium';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _omit from 'lodash/omit';
import Accordian from '../../../components/Accordion';
import AudioPicker from './AudioPicker';
import format from 'date-fns/format';
import style from './style.css';
import Player from './Player';
// import fakeData from './fakeData';

import { Snapshoter } from './VideoJsSnapshot/index.js';
const Boilerplate = window.cccisd.boilerplate;

export const revLanguages = {
    en: 'English',
    cs: 'Czech',
    ja: 'Japanese',
    'es-la': 'Spanish (Latin America)',
    nl: 'Dutch',
    ko: 'Korean',
    'es-es': 'Spanish (Spain)',
    fr: 'French',
    pl: 'Polish',
    ar: 'Arabic',
    de: 'German',
    'pt-br': 'Portuguese (Brazil)',
    'zh-si': 'Chinese (Simplified)',
    hi: 'Hindi',
    ru: 'Russian',
    'zh-tr': 'Chinese (Traditional)',
    it: 'Italian',
    tr: 'Turkish',
};

export default class VideoSettings extends React.Component {
    static propTypes = {
        loadData: PropTypes.func,
        row: PropTypes.object,
    };

    modal = React.createRef();

    state = {
        initialValues: {},
        loading: true,
        accordianState: {},
        currentValues: {},
        versionData: {},
    };

    componentDidMount = async () => {
        const { row } = this.props;

        const res = await axios.get(Boilerplate.route('api.producer.video.show', { video: row['pawn.pawnId'] })); // FOR RELEASE: {video: row['pawn.pawnId']}

        const versionData = res.data && res.data.data && res.data.data.versions.length > 0 ? res.data.data : null;
        let videoSettings = this.getNewFormValues(versionData);

        if (res.data.data.annotations !== null) {
            videoSettings.annotations = res.data.data.annotations;
        }
        if (res.data.data.languages !== null) {
            videoSettings.languages = res.data.data.languages;
        }
        if (res.data.data.chapters !== null) {
            videoSettings.chapters = res.data.data.chapters;
        }
        if (res.data.data.locked_version !== null) {
            videoSettings.currentVersion = res.data.data.locked_version;
        }
        if (res.data.data.posterUrl) {
            videoSettings.image = { url: res.data.data.posterUrl };
        }

        if (videoSettings) {
            const initialValues = this.getInitialValues(videoSettings, versionData);

            this.setState({
                currentVersion: initialValues.currentVersion,
                initialValues,
                loading: false,
                accordianState: initialValues.accordianState,
                versionData,
            });
        } else {
            // New Form - NO saved videoSettings

            let newFormSettings = this.getNewFormValues(versionData);

            this.setState({
                currentVersion: newFormSettings.currentVersion,
                initialValues: newFormSettings,
                loading: false,
                versionData,
            });
        }
    };

    componentDidUpdate = (prevProps, prevState) => {
        // Update form state on table refresh
        if (!_isEqual(prevProps, this.props)) {
            const { row } = this.props;
            const videoSettings = JSON.parse(row['fields.videoSettings']);

            if (videoSettings) {
                const formattedSettings = this.getInitialValues(videoSettings);

                this.setState({
                    initialValues: formattedSettings,
                    accordianState: formattedSettings.accordianState,
                    currentValues: {},
                });
            }
        }
    };

    refreshVersionData = async () => {
        const res = await axios.get(
            Boilerplate.route('api.producer.video.refresh', {
                video: this.props.row['pawn.pawnId'],
            })
        ); // FOR RELEASE: {video: row['pawn.pawnId']}

        if (res && res.data && res.data.status === 'success') {
            const resp = await axios.get(
                Boilerplate.route('api.producer.video.show', {
                    video: this.props.row['pawn.pawnId'],
                }) // FOR RELEASE: {video: row['pawn.pawnId']}
            );

            if (resp && resp.data && resp.data.status === 'success') {
                this.setState({
                    versionData: resp.data.data,
                });
                notification({ message: 'Version data has been refreshed.', type: 'success' });
            } else {
                notification({ message: 'Failed to retrieve version data.', type: 'danger' });
            }
        } else {
            notification({ message: 'Failed to refresh version data.', type: 'danger' });
        }
    };

    getPlayerProps = (values, versionNum, defaults) => {
        const videoId = String(this.props.row['pawn.pawnId']);

        const { versionData } = this.state;
        const { languages, image, chapters, annotations } = values;
        const langArr = this.getLanguageArr(languages);

        const chapterSeconds = this.convertToSeconds(chapters, 'chapters');

        const chapterArr = langArr.map(lang => {
            const list = chapterSeconds.map(chapt => {
                return { time: chapt.start, label: chapt.label[lang] };
            });
            return { language: lang, list };
        });

        const annoSeconds = this.convertToSeconds(annotations, 'annotation');

        const annoArr = langArr.map(lang => {
            const list = annoSeconds.map(anno => {
                return { start: anno.start, end: anno.end, content: anno.content[lang] };
            });
            return { language: lang, list };
        });

        const useMostRecent = versionNum === 'Use most recent';

        const currentVersion =
            versionNum && !useMostRecent
                ? versionData.versions.find(item => +item.version_number === +versionNum)
                : versionData.versions
                      .slice()
                      .reverse()
                      .find(ver => ver.status === 'COMPLETED');

        const paramOne = videoId.length <= 3 ? videoId.padStart(3, '0') : videoId.substr(videoId.length - 3);
        const paramTwo = videoId.padStart(6, '0');
        const paramThree = versionNum.toString().padStart(3, '0');
        const sources = [
            {
                src:
                    versionNum === 'Use most recent'
                        ? versionData.videoUrl
                        : `https://cccisd-media.s3.amazonaws.com/${paramOne}/${paramTwo}/${paramThree}/video.m3u8`,
                type: 'application/x-mpegURL',
            },
        ];

        const completed = currentVersion && currentVersion.status === 'COMPLETED';

        let posters;
        const resolutions = ['720p', '480p', '360p'];
        const widths = {
            '720p': 1280,
            '480p': 854,
            '360p': 640,
        };
        if (completed) {
            if (defaults) {
                posters = resolutions.map(size => {
                    const poster = this.getMediaUrl(
                        currentVersion.content[size].thumbnailPattern,
                        +versionNum,
                        'image'
                    );
                    return { src: poster, width: widths[size] };
                });
            } else {
                posters = image.url
                    ? resolutions.map(size => {
                          return { src: image.url, width: widths[size] };
                      })
                    : resolutions.map(size => {
                          const poster = this.getMediaUrl(
                              currentVersion.content[size].thumbnailPattern,
                              +versionNum,
                              'image'
                          );
                          return { src: poster, width: widths[size] };
                      });
            }

            return { sources, posters, chapters: chapterArr, annotations: annoArr, image };
        }
        return null;
    };

    getMediaUrl = (pattern, versionNumber, type) => {
        const pawnId = this.props.row['pawn.pawnId']; // FOR RELEASE: this.props.row['pawn.pawnId']
        const videoId = pawnId.toString();

        const paramOne = videoId.length <= 3 ? videoId.padStart(3, '0') : videoId.substr(videoId.length - 3);
        const paramTwo = videoId.padStart(6, '0');
        const paramThree = versionNumber.toString().padStart(3, '0');
        let paramFour = pattern;
        if (type === 'image') {
            paramFour = pattern.replace('{count}', '00001.png');
        }

        return `https://cccisd-media.s3.amazonaws.com/${paramOne}/${paramTwo}/${paramThree}/${paramFour}`;
    };

    getLanguageArr = obj => {
        let languageArr = [];
        for (const [key, value] of Object.entries(obj)) {
            if (value) {
                languageArr.push(key);
            }
        }
        return languageArr;
    };

    getSaveFormat = values => {
        const formattedValues = { ...values };
        const langArr = this.getLanguageArr(values.languages);
        if (values.annotations.length > 0) {
            const annotations = this.convertToSeconds(values.annotations, 'annotation');
            const annoArr = langArr.map(lang => {
                const list = annotations.map(anno => {
                    return { start: anno.start, end: anno.end, content: anno.content[lang] };
                });
                return { language: lang, list };
            });
            formattedValues.annotations = annoArr;
        }
        if (values.chapters.length > 0) {
            const chapters = this.convertToSeconds(values.chapters, 'chapters');
            const chapterArr = langArr.map(lang => {
                const list = chapters.map(chapt => {
                    return { time: chapt.start, label: chapt.label[lang] };
                });
                return { language: lang, list };
            });
            formattedValues.chapters = chapterArr;
        }
        formattedValues.accordianState = this.state.accordianState;
        return formattedValues;
    };

    getNewFormValues = versionData => {
        const languages = {};
        Object.keys(revLanguages).forEach((lang, i) => {
            if (i === 0) {
                languages[lang] = true;
            } else {
                languages[lang] = false;
            }
        });

        let newAudio = [];
        let image;
        let defaultPoster;
        let currentVersion;
        if (versionData) {
            const { latestVersion, versions } = versionData;
            const completed = latestVersion && latestVersion.status === 'COMPLETED';

            const firstCompletedVersion = completed
                ? latestVersion
                : versions
                      .slice()
                      .reverse()
                      .find(item => item.status === 'COMPLETED');

            // default latest if no form settings
            currentVersion = 'Use most recent';

            defaultPoster =
                firstCompletedVersion &&
                this.getMediaUrl(
                    firstCompletedVersion.content['720p'].thumbnailPattern,
                    firstCompletedVersion.version_number,
                    'image'
                );

            image =
                (versionData.posterUrl && { url: versionData.posterUrl }) ||
                (defaultPoster ? { url: defaultPoster } : {});

            const audioTracks = versionData.audio_tracks;

            if (audioTracks && audioTracks.length > 0) {
                audioTracks.forEach((item, i) => {
                    const url = item.url;
                    const fileName = url.split('/');
                    newAudio.push({
                        track: {
                            name: fileName[fileName.length - 1],
                            url,
                        },
                        type: item.type,
                        name: item.label,
                        language: item.language,
                        id: item.id,
                        filename: item.filename,
                    });
                });
            }
        }
        const newVideoSettings = {
            languages,
            audio: newAudio,
            chapters: [],
            image,
            annotations: [],
            currentVersion,
            accordianState: {
                audio: false,
                chapters: false,
                image: false,
                annotations: false,
                versions: false,
                languages: false,
            },
        };

        return newVideoSettings;
    };

    getInitialValues = (videoSettings, versionData) => {
        // if no audio or image => check for currentVersionComplete ? get defaults : checkAnyComplete ? getDefaults : leave audio/image blank
        const formattedSettings = { ...videoSettings };
        const { audio, image, chapters, annotations, currentVersion } = videoSettings;
        if (versionData) {
            if (_isEmpty(audio)) {
                const audioTracks = versionData.audio_tracks;
                let newAudio = [];

                if (audioTracks && audioTracks.length > 0) {
                    audioTracks.forEach((item, i) => {
                        const url = item.url;
                        const fileName = url.split('/');
                        newAudio.push({
                            track: {
                                name: fileName[fileName.length - 1],
                                url,
                            },
                            type: i === 0 ? 'main' : 'translation',
                            name: item.label && i !== 0 ? item.label : 'Main',
                            language: item.language,
                            id: item.id,
                        });
                    });
                }

                formattedSettings.audio = newAudio;
            }

            if (_isEmpty(image)) {
                const latestIncomplete =
                    versionData && versionData.latestVersion && versionData.latestVersion.status !== 'COMPLETED';

                const latestCompleted =
                    versionData &&
                    versionData.versions
                        .slice()
                        .reverse()
                        .find(ver => ver.status === 'COMPLETED');
                let selectedVersion =
                    currentVersion &&
                    currentVersion !== 'Use most recent' &&
                    versionData.versions.find(ver => ver.version_number === +currentVersion);

                if (!currentVersion) {
                    selectedVersion = latestIncomplete ? latestCompleted : versionData.latestVersion;
                }

                // if currentVersion => currentVersion
                // !currentVersion => latestVersionComplete ? latestVersion : findLatest
                // No Complete Version = no poster?

                const defaultPoster =
                    selectedVersion &&
                    this.getMediaUrl(
                        selectedVersion.content['720p'].thumbnailPattern,
                        selectedVersion.version_number,
                        'image'
                    );

                formattedSettings.image = defaultPoster ? { url: defaultPoster } : {};
            }
        }

        const formattedChapters = chapters && chapters.length > 0 ? this.formatForForm(chapters, 'chapter') : [];

        const formattedAnnotations =
            annotations && annotations.length > 0 ? this.formatForForm(annotations, 'annotation') : [];
        if (formattedSettings.currentVersion !== 'Use most recent') {
            formattedSettings.currentVersion = +formattedSettings.currentVersion;
        }
        formattedSettings.chapters = formattedChapters;
        formattedSettings.annotations = formattedAnnotations;

        return formattedSettings;
    };

    // Keep track so we can save it
    updateOpenState = (open, category) => {
        this.setState({
            accordianState: {
                ...this.state.accordianState,
                [category]: open,
            },
        });
    };

    // Prevent loss of unsaved changes
    confirmClose = () => {
        const { initialValues, currentValues } = this.state;
        const initial = _omit(initialValues, 'accordianState');
        const current = _omit(currentValues, 'accordianState');
        const identical = _isEqual(initial, current);

        if (_isEmpty(currentValues) || identical) {
            this.props.closeModal();
            this.props.loadData();
        } else if (!identical) {
            Confirm({
                message: 'Unsaved Changes',
                description: 'Do you want to discard unsaved changes?',
                confirmLabel: 'Yes',
                abortLabel: 'No',
            })
                .then(() => {
                    this.setState({
                        currentValues: {},
                    });
                    this.props.closeModal();
                    this.props.loadData();
                })
                .fail(() => {});
        }
    };

    onSubmit = async values => {
        const formattedData = this.getSaveFormat(values);

        const pawnId = this.props.row['pawn.pawnId'];
        formattedData.currentVersion =
            formattedData.currentVersion === 'Use most recent' ? 0 : +formattedData.currentVersion;

        this.setState({
            loading: true,
        });

        const response = await axios.put(Boilerplate.route('api.producer.video.update', { video: pawnId }), {
            ...formattedData,
        });

        this.setState({
            loading: false,
        });

        if (response && response.data.status === 'success') {
            notification({ message: 'Video settings successfully saved', type: 'success' });
            this.props.closeModal();
        } else {
            notification({ message: 'An error occured saving video settings', type: 'Danger' });
        }
        // Update fields.currentVersion
        this.props.loadData();
    };

    convertToSeconds = (collection, type) => {
        const isAnnotation = type === 'annotation';

        if (type === 'validation') {
            const startSeconds = +collection.startMinutes * 60 + +collection.startSeconds;
            const endSeconds = +collection.endMinutes * 60 + +collection.endSeconds;

            return {
                start: startSeconds,
                end: endSeconds,
            };
        }

        const convertedData = collection.map(item => {
            const startSeconds = +item.startMinutes * 60 + +item.startSeconds;

            if (isAnnotation) {
                const endSeconds = +item.endMinutes * 60 + +item.endSeconds;
                return {
                    start: startSeconds,
                    end: endSeconds,
                    content: item.content,
                    url: item.url,
                };
            }
            return {
                start: startSeconds,
                label: item.label,
            };
        });
        return convertedData;
    };

    padZero = number => {
        const numString = String(number);

        if (numString.length === 1) {
            return numString.padStart(2, '0');
        }

        if (numString.includes('.')) {
            const splitString = numString.split('.');
            const wholeNumber = splitString[0];
            const decimal = splitString[1];

            if (wholeNumber.length === 1) {
                return wholeNumber.padStart(2, '0') + '.' + decimal;
            }
        }

        return number;
    };

    formatForForm = (array, type) => {
        const languages = array.map(item => item.language);

        const enList = array[0].list;
        const isChapter = type === 'chapter';
        const formData = enList.map((item, i) => {
            let label = {};
            let times = [];

            const objName = isChapter ? 'label' : 'content';
            const timeName = isChapter ? 'time' : 'start';

            array.forEach(lang => {
                if (lang.list[i].time === item.time) {
                    times.push(lang.list[i]);
                }
            });

            languages.forEach((lang, id) => {
                label[lang] = times[id][objName];
            });

            const startMinutes = Math.floor(+item[timeName] / 60);
            const startSeconds = +item[timeName] % 60 === 0 ? '00' : this.padZero(+item[timeName] % 60);

            if (isChapter) return { label, startMinutes, startSeconds };

            const endMinutes = Math.floor(+item.end / 60);
            const endSeconds = +item.end % 60 === 0 ? '00' : this.padZero(+item.end % 60);

            return { content: label, startMinutes, startSeconds, endMinutes, endSeconds };
        });

        return formData;
    };

    renderLanguageSelection = () => {
        const langKeys = Object.keys(revLanguages);

        return (
            <div className={style.languages}>
                {langKeys.map((lan, i) => {
                    if (i % 3 === 0) {
                        return (
                            <div className={style.languageRow} key={i}>
                                {[0, 1, 2].map((colNum, id) => {
                                    const langKey = langKeys[i + colNum];
                                    return (
                                        langKey && (
                                            <div className={style[`col${colNum + 1}`]} key={id}>
                                                <Field
                                                    name={`languages.${langKey}`}
                                                    component={CccisdCheckboxInput}
                                                    label={`${revLanguages[langKey]}`}
                                                    labelNotBold
                                                />
                                            </div>
                                        )
                                    );
                                })}
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
        );
    };

    // HERE!
    renderPosterImage = () => {
        const { versionData, currentVersion } = this.state;
        const videoUrl = versionData && versionData.videoUrl;
        // return ;
        const useMostRecent = currentVersion === 'Use most recent';

        const latestCompleted =
            versionData &&
            versionData.versions
                .slice()
                .reverse()
                .find(ver => ver.status === 'COMPLETED');

        let useVersion;
        let defaultPoster;

        if (versionData) {
            useVersion = useMostRecent
                ? latestCompleted
                : versionData.versions.find(item => item.version_number === currentVersion);

            defaultPoster =
                useVersion &&
                this.getMediaUrl(useVersion.content['720p'].thumbnailPattern, useVersion.version_number, 'image');
        }

        return (
            <div className={style.posterImage}>
                <Field name="image">
                    {({ field, form }) => {
                        const isDefault =
                            (versionData &&
                                !versionData.custom_poster &&
                                versionData.posterUrl === form.values.image.url) ||
                            (versionData && defaultPoster === versionData.custom_poster) ||
                            defaultPoster === form.values.image.url;
                        const noImage = !defaultPoster && !form.values.image.url;

                        return (
                            <>
                                <h4 style={{ lineHeight: 1.6 }}>Current Image:</h4>

                                <div className={style.currentImage}>
                                    {!noImage ? (
                                        <img src={form.values.image.url} alt="Tile Icon" />
                                    ) : (
                                        <div className={style.greytext}>
                                            No default poster available. <br />
                                            Click upload to add a new poster.
                                        </div>
                                    )}
                                </div>
                                {!noImage && (
                                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                        {!isDefault ? (
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    form.setFieldValue('image', {
                                                        url: defaultPoster,
                                                    });
                                                }}
                                            >
                                                Use Default
                                            </button>
                                        ) : (
                                            '(Default)'
                                        )}
                                    </div>
                                )}
                                <div className={!noImage ? style.editButton : style.uploadButton}>
                                    <CccisdFieldWrapper field={field} form={form} label="">
                                        <ImagePicker
                                            trigger={
                                                <button type="button" className={`btn btn-primary ${style.edit}`}>
                                                    {!noImage ? <Pencil /> : <Upload />}
                                                    <span style={{ marginLeft: '0.5em' }}>
                                                        {!noImage ? 'Edit' : 'Upload'}
                                                    </span>
                                                </button>
                                            }
                                            onChange={value => form.setFieldValue('image', value)}
                                        />
                                    </CccisdFieldWrapper>
                                </div>
                                <div className={style.snapshotButtonWrapper}>
                                    <Snapshoter videoUrl={videoUrl} form={form} row={this.props.row} />
                                </div>
                            </>
                        );
                    }}
                </Field>
            </div>
        );
    };

    renderArrayFields = type => {
        const tinyMceSettings = {
            plugins: ['link spellchecker lists charmap'],
            content_style: '.mce-content-body { width: 335px; height: 73px !important; }',
            toolbar: 'undo redo | styleselect | bold italic | link',
        };
        if (type !== 'audiotrack') {
            const plural = type + 's';
            const isAnnotation = type === 'annotation';
            return (
                <FieldArray
                    validateOnChange={false}
                    name={plural}
                    render={({ form: { values, errors }, push, remove }) => {
                        const selectedLanguages = this.getLanguageArr(values.languages);
                        const errorType = isAnnotation ? errors.annotations : errors.chapters;

                        return (
                            <div className={style.annotationList}>
                                {values[plural].map((item, index) => (
                                    <div className={style.annotationItem} key={index}>
                                        <h4 className={style.arrayItemHeader}>
                                            {isAnnotation ? 'Annotation' : 'Chapter'} {index + 1}
                                        </h4>
                                        {errorType && errorType[index] && errorType[index].overlap && (
                                            <div className={`${style.overlap} alert alert-danger`}>
                                                {errorType[index].overlap}
                                                <div>Conflict(s): {errorType[index].conflicts}</div>
                                            </div>
                                        )}
                                        <div className={isAnnotation ? style.annotationStart : style.chapterTime}>
                                            <label>{isAnnotation ? 'Start:' : 'Time:'}</label>
                                            <div className={style.timeFields}>
                                                <Field
                                                    className={`form-control ${style.minutesInput}`}
                                                    name={`${plural}[${index}].startMinutes`}
                                                    placeholder="MM"
                                                />
                                                <span className={style.colon}>:</span>
                                                <Field
                                                    className={`form-control ${style.secondsInput}`}
                                                    name={`${plural}[${index}].startSeconds`}
                                                    placeholder="SS"
                                                />
                                            </div>
                                        </div>
                                        {errorType && errorType[index] && errorType[index].start && (
                                            <div className={isAnnotation ? style.error : style.chapterError}>
                                                {errorType[index].start}{' '}
                                            </div>
                                        )}
                                        {isAnnotation && (
                                            <div className={style.annotationEnd}>
                                                <label>End:</label>
                                                <div className={style.timeFields}>
                                                    <Field
                                                        name={`${plural}.${index}.endMinutes`}
                                                        className={`form-control ${style.minutesInput}`}
                                                        placeholder="MM"
                                                    />
                                                    <span className={style.colon}>:</span>
                                                    <Field
                                                        name={`${plural}.${index}.endSeconds`}
                                                        className={`form-control ${style.secondsInput}`}
                                                        placeholder="SS"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {errorType && errorType[index] && errorType[index].end && (
                                            <div className={style.error}>{errorType[index].end} </div>
                                        )}

                                        {/* MUST DYNAMICALLY RENDER Annotation - Content // Chapter - Label w/ Languages */}
                                        {isAnnotation &&
                                            selectedLanguages.map((lang, id) => {
                                                return (
                                                    <React.Fragment key={id}>
                                                        <div className={style.note}>
                                                            <div>
                                                                <label>Content: </label>
                                                                <div>
                                                                    <Tooltip
                                                                        title={`${revLanguages[lang]}`}
                                                                        size="medium"
                                                                    >{`(${lang.toUpperCase()})`}</Tooltip>
                                                                </div>
                                                            </div>
                                                            <Field
                                                                className={style.noteText}
                                                                name={`${plural}.${index}.content.${lang}`}
                                                                component={CccisdWysiwyg}
                                                                settings={tinyMceSettings}
                                                            />
                                                        </div>
                                                        {errorType &&
                                                            errorType[index] &&
                                                            errorType[index].content &&
                                                            errorType[index].content[lang] && (
                                                                <div
                                                                    className={style.error}
                                                                    style={{
                                                                        marginTop: '-10px',
                                                                        marginBottom: '15px',
                                                                    }}
                                                                >
                                                                    {errorType[index].content[lang]}
                                                                </div>
                                                            )}
                                                    </React.Fragment>
                                                );
                                            })}
                                        {!isAnnotation &&
                                            selectedLanguages.map((lang, id) => {
                                                return (
                                                    <React.Fragment key={id}>
                                                        <div className={style.chapterLabel} key={id}>
                                                            <div className={style.language}>
                                                                <label>Label:</label>
                                                                <div>
                                                                    <Tooltip
                                                                        title={`${revLanguages[lang]}`}
                                                                        size="medium"
                                                                    >{`(${lang.toUpperCase()})`}</Tooltip>
                                                                </div>
                                                            </div>
                                                            <Field
                                                                name={`${plural}.${index}.label.${lang}`}
                                                                component={CccisdInput}
                                                            />
                                                        </div>
                                                        {errorType &&
                                                            errorType[index] &&
                                                            errorType[index].label &&
                                                            errorType[index].label[lang] && (
                                                                <div
                                                                    className={style.chapterError}
                                                                    style={{
                                                                        marginTop: '-25px',
                                                                    }}
                                                                >
                                                                    {errorType[index].label[lang]}
                                                                </div>
                                                            )}
                                                    </React.Fragment>
                                                );
                                            })}
                                        {/* end dynamic render */}
                                        <button
                                            className={`close ${style.removeButton}`}
                                            type="button"
                                            onClick={() => {
                                                remove(index);
                                            }}
                                        >
                                            <Cross />
                                        </button>
                                        <hr />
                                    </div>
                                ))}
                                <button
                                    className={`btn btn-primary ${style.addButton}`}
                                    type="button"
                                    onClick={() => {
                                        const key = isAnnotation ? 'content' : 'label';
                                        const newObj = isAnnotation
                                            ? {
                                                  startMinutes: '',
                                                  startSeconds: '',
                                                  endMinutes: '',
                                                  endSeconds: '',
                                                  content: {},
                                              }
                                            : {
                                                  startMinutes: '',
                                                  startSeconds: '',
                                                  label: {},
                                              };

                                        selectedLanguages.forEach(lang => {
                                            newObj[key][lang] = '';
                                        });

                                        push(newObj);
                                    }}
                                >
                                    <Plus /> <span>Add {isAnnotation ? 'Annotation' : 'Chapter'}</span>
                                </button>
                            </div>
                        );
                    }}
                />
            );
        }

        const audioTypes = [
            {
                value: 'translation',
                label: 'Translation',
            },
            {
                value: 'alternative',
                label: 'Alternative',
            },
            {
                value: 'descriptions',
                label: 'Descriptions',
            },
            {
                value: 'commentary',
                label: 'Commentary',
            },
            {
                value: '',
                label: 'None',
            },
        ];

        const mainAudioType = [
            {
                value: 'main',
                label: 'Main',
            },
        ];

        return (
            <FieldArray
                validateOnChange={false}
                name="audio"
                render={({ form: { values, errors }, push, remove }) => {
                    let audioLanguages = [
                        {
                            value: '',
                            label: '  -- Choose a Language --',
                        },
                    ];
                    for (const [key, value] of Object.entries(revLanguages)) {
                        if (values.languages[key]) {
                            audioLanguages.push({ value: key, label: value });
                        }
                    }
                    const noAudio = _isEmpty(values.audio);
                    let audioTrackCounter = 1;

                    const orderedAudio = noAudio
                        ? []
                        : values.audio.sort((a, b) => {
                              if (a.type === 'main') {
                                  return -1;
                              }
                              if (b.type === 'main') {
                                  return 1;
                              }
                              return 0;
                          });

                    return (
                        <div className={style.audioTrackList}>
                            {noAudio && (
                                <h4 className={`${style.center} ${style.greytext}`} style={{ marginBottom: '20px' }}>
                                    No main audio track available.
                                </h4>
                            )}
                            {orderedAudio.map((track, index) => {
                                const isMain = orderedAudio[index].type === 'main';
                                const audioFile = orderedAudio[index].track.url;
                                const preview = audioFile && new Audio(audioFile);
                                const audioErrors = errors && errors.audio;

                                if (!isMain) {
                                    audioTrackCounter++;
                                }

                                const { versionData } = this.state;

                                const useMostRecent = values.currentVersion === 'Use most recent';
                                const latestCompleted =
                                    versionData &&
                                    versionData.versions
                                        .slice()
                                        .reverse()
                                        .find(version => version.status === 'COMPLETED');

                                const selectedVersion = useMostRecent
                                    ? latestCompleted
                                    : versionData &&
                                      versionData.versions &&
                                      versionData.versions.find(ver => +ver.version_number === +values.currentVersion);
                                const activeVersion = selectedVersion.version_number.toString().padStart(3, '0');

                                if (isMain && orderedAudio[index].filename.substring(0, 3) !== activeVersion) {
                                    return;
                                }

                                return (
                                    <div className={style.audioTrack} key={index}>
                                        {!isMain && (
                                            <button
                                                className={`close ${style.removeButton}`}
                                                type="button"
                                                onClick={() => remove(index)}
                                            >
                                                <Cross />
                                            </button>
                                        )}
                                        <div className={style.audioHeader}>
                                            <h4>
                                                Track {audioTrackCounter} {isMain && <span> - Default</span>}
                                            </h4>
                                            {preview && (
                                                <Modal
                                                    trigger={
                                                        <button
                                                            type="button"
                                                            className={`btn btn-primary ${style.audioPlay}`}
                                                        >
                                                            <Tooltip
                                                                title="Preview Audio"
                                                                size="medium"
                                                                placement="bottom"
                                                            >
                                                                <Audio />
                                                            </Tooltip>
                                                        </button>
                                                    }
                                                    size="medium"
                                                    title={`Audio Preview - ${orderedAudio[index].name}`}
                                                >
                                                    <div className={style.center}>
                                                        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                                                        <audio controls autoPlay>
                                                            <source src={audioFile} type="audio/aac" />
                                                            <source src={audioFile} type="audio/mpeg" />
                                                            <source src={audioFile} type="audio/ogg" />
                                                            Audio is not supported by your browser.
                                                        </audio>
                                                    </div>
                                                </Modal>
                                            )}
                                        </div>
                                        <div className={style.audioName}>
                                            <label>Name:</label>
                                            <Field name={`audio.${index}.name`} component={CccisdInput} />
                                        </div>
                                        {audioErrors && audioErrors[index] && audioErrors[index].name && (
                                            <div className={style.error}>{audioErrors[index].name}</div>
                                        )}
                                        <div
                                            className={style.audioFile}
                                            style={{ display: `${isMain ? 'none' : 'flex'}` }}
                                        >
                                            <label>Audio File:</label>
                                            <Field name={`audio.${index}.track`}>
                                                {({ field, form }) => {
                                                    const noTrack = _isEmpty(orderedAudio[index].track);
                                                    return (
                                                        <>
                                                            {noTrack ? (
                                                                <div className="form-control">Upload New Audio</div>
                                                            ) : (
                                                                <div className="form-control" disabled={isMain}>
                                                                    {orderedAudio[index].track.name}
                                                                </div>
                                                            )}
                                                            <div className={style.uploadButton}>
                                                                <CccisdFieldWrapper field={field} form={form}>
                                                                    {!isMain && (
                                                                        <AudioPicker
                                                                            trigger={
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary"
                                                                                >
                                                                                    <Upload />
                                                                                </button>
                                                                            }
                                                                            onChange={value => {
                                                                                form.setFieldValue(
                                                                                    `audio.${index}.track`,
                                                                                    value
                                                                                );
                                                                            }}
                                                                        />
                                                                    )}
                                                                </CccisdFieldWrapper>
                                                            </div>
                                                        </>
                                                    );
                                                }}
                                            </Field>
                                        </div>
                                        {audioErrors && audioErrors[index] && audioErrors[index].track && (
                                            <div className={style.error}>{audioErrors[index].track}</div>
                                        )}
                                        <div className={style.audioLanguage}>
                                            <label>Language:</label>
                                            <Field
                                                name={`audio.${index}.language`}
                                                component={CccisdSelect}
                                                options={audioLanguages}
                                                disabled={isMain}
                                            />
                                        </div>
                                        {audioErrors && audioErrors[index] && audioErrors[index].language && (
                                            <div className={style.error}>{audioErrors[index].language}</div>
                                        )}

                                        <div className={style.audioType}>
                                            <label>Type:</label>
                                            <Field
                                                className={style.center}
                                                name={`audio.${index}.type`}
                                                component={CccisdSelect}
                                                options={isMain ? mainAudioType : audioTypes}
                                                disabled={isMain}
                                            />
                                        </div>
                                        <hr />
                                    </div>
                                );
                            })}
                            <button
                                className={`btn btn-primary ${style.addButton}`}
                                type="button"
                                disabled={noAudio}
                                onClick={() =>
                                    push({
                                        track: {},
                                        name: '',
                                        language: '',
                                        type: 'translation',
                                    })
                                }
                            >
                                <Plus /> <span>Add Audio Track</span>
                            </button>
                        </div>
                    );
                }}
            />
        );
    };

    renderVersions = (values, currentVersion) => {
        if (this.state.versionData) {
            const {
                versionData: { versions },
            } = this.state;
            const versionList =
                versions &&
                versions.map(item => {
                    return {
                        value: item.version_number,
                        label: item.version_number.toString().padStart(3, '0'),
                    };
                });
            const notCompleted = versions.filter(item => item.status !== 'COMPLETED');

            if (versionList && versionList.length > 0) {
                return (
                    <div className={style.versions}>
                        <div className={style.versionList}>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Version</th>
                                        <th>Preview</th>
                                        <th>Uploaded</th>
                                        <th>MP4</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {versions.map((item, i) => {
                                        const completed = item.status === 'COMPLETED';
                                        let status;

                                        switch (item.status) {
                                            case 'COMPLETED':
                                                status = format(Date.parse(item.updated_at), 'MM-dd-yyyy');
                                                break;
                                            case 'PROGRESS':
                                                status = 'In Progress';
                                                break;
                                            case 'ERROR':
                                                status = 'Error';
                                                break;
                                            default:
                                                status = item.status;
                                        }
                                        const label = item.version_number.toString().padStart(3, '0');

                                        const isCurrentVersion = +item.version_number === +currentVersion;

                                        const latestCompleted = versions
                                            .slice()
                                            .reverse()
                                            .find(version => version.status === 'COMPLETED');

                                        const isMostRecent =
                                            latestCompleted && +item.version_number === latestCompleted.version_number;

                                        const playerProps =
                                            completed && this.getPlayerProps(values, item.version_number, true);

                                        return (
                                            <tr className={style.versionItem} key={i}>
                                                <td
                                                    className={
                                                        isCurrentVersion || (!+currentVersion && isMostRecent)
                                                            ? style.columnSelected
                                                            : style.columnOne
                                                    }
                                                >
                                                    {isCurrentVersion || (!+currentVersion && isMostRecent) ? (
                                                        <>
                                                            <CheckMark /> {label}
                                                        </>
                                                    ) : (
                                                        label
                                                    )}
                                                </td>
                                                <td className={style.columnTwo}>
                                                    {completed && (
                                                        <Modal
                                                            trigger={
                                                                <button type="button" className="btn btn-primary">
                                                                    <Tooltip
                                                                        title="Preview Version"
                                                                        size="medium"
                                                                        placement="top"
                                                                    >
                                                                        <Play />
                                                                    </Tooltip>
                                                                </button>
                                                            }
                                                            size="large"
                                                            title={`Preview Version - ${label}`}
                                                        >
                                                            <Player {...playerProps} />
                                                        </Modal>
                                                    )}
                                                </td>
                                                <td>{status}</td>
                                                <td>
                                                    <Tooltip title="Copy Video URL to Clipboard" placement="bottom">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={() => {
                                                                const clipId = this.state.versionData.pawn_id + '';
                                                                const clipPad = clipId.padStart(6, '0');
                                                                const clipLast3 = clipId.substr(clipId.length - 3);
                                                                const version = item.version_number + '';
                                                                const versionPad = version.padStart(3, '0');
                                                                const url =
                                                                    'https://cccisd-media.s3.amazonaws.com/' +
                                                                    clipLast3 +
                                                                    '/' +
                                                                    clipPad +
                                                                    '/' +
                                                                    versionPad +
                                                                    '/video/video.mp4';
                                                                navigator.clipboard.writeText(url);
                                                                notification({
                                                                    message: 'Copied to clipboard!',
                                                                    type: 'success',
                                                                });
                                                            }}
                                                        >
                                                            MP4
                                                        </button>
                                                    </Tooltip>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className={style.versionAdvanced}>
                            {notCompleted.length > 0 && (
                                <HiddenBlock title="Advanced">
                                    <>
                                        {notCompleted.map((item, i) => {
                                            const label = item.version_number.toString().padStart(3, '0');
                                            if (item.status === 'PROGRESS') {
                                                const date = format(Date.parse(item.updated_at), 'MM-dd-yyyy');
                                                return (
                                                    <div key={i} className={style.advancedItem}>
                                                        <div className={style.label}>{label} - In Progress:</div>
                                                        <div className={style.progress}>
                                                            <div className={style.message}>
                                                                In progress since {date}.
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }

                                            if (item.status === 'ERROR') {
                                                const message = item.content.statusDetail;
                                                return (
                                                    <div key={i} className={style.advancedItem}>
                                                        <div className={style.label}>{label} - Error:</div>

                                                        <div className={style.error}>
                                                            <div className={style.message}>{message}</div>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                    </>
                                </HiddenBlock>
                            )}
                            <div className={style.versionRefresh}>
                                <button type="button" className="btn btn-primary" onClick={this.refreshVersionData}>
                                    <Refresh /> Refresh
                                </button>
                            </div>
                        </div>
                    </div>
                );
            }
        }
        return (
            <div>
                <h4 className={`${style.center} ${style.greytext}`}>No version data.</h4>
                <div className={style.versionRefresh}>
                    <button type="button" className="btn btn-primary" onClick={this.refreshVersionData}>
                        <Refresh /> Refresh
                    </button>
                </div>
            </div>
        );
    };

    validateTimespans = (array, type) => {
        const isAnnotation = type === 'annotations';
        const isChapter = type === 'chapters';
        const timespanErrors = [];

        const timespans = array.map((item, i) => {
            // uses validation for simple conversion despite being chapter or annotation
            const timespan = this.convertToSeconds(item, 'validation');

            timespan.index = i;

            return timespan;
        });

        array.forEach((item, i) => {
            const error = {};
            const { startMinutes, startSeconds, endMinutes, endSeconds } = item;
            const { start, end } = this.convertToSeconds(item, 'validation');
            const startBlank = startMinutes === '' && startSeconds === '';
            const endBlank = endMinutes === '' && endSeconds === '';
            const startNaN = Number.isNaN(start);
            const endNaN = Number.isNaN(end);

            // timespans cannot overlap

            if (isAnnotation) {
                const overlap = timespans.filter(
                    timespan => timespan.start <= end && start <= timespan.end && timespan.index !== i
                );

                if (overlap.length > 0 && !startNaN && !endNaN && !startBlank && !endBlank && start < end) {
                    const conflicts = overlap
                        .map(thing => {
                            return `Annotation ${thing.index + 1}`;
                        })
                        .toString()
                        .replace(/,/g, ', ');

                    error.overlap = `Annotation timespans cannot overlap.`;

                    error.conflicts = conflicts;
                }

                if (start > end) {
                    error.end = 'End time cannot be before start time.';
                }
                if (endNaN) {
                    error.end = 'End time must be numbers.';
                }
                // End cannot be 0
                if (end === 0) {
                    error.end = `End time cannot equal 0.`;
                }
                // End cannot be blank
                if (endBlank) {
                    error.end = 'End time is required.';
                }
            }

            if (isChapter && !startBlank) {
                let overlap = timespans.filter(thing => thing.index !== i && thing.start === start);

                if (overlap.length > 0) {
                    const conflicts = overlap
                        .map(thing => {
                            return `Chapter ${thing.index + 1}`;
                        })
                        .toString()
                        .replace(/,/g, ', ');

                    if (!startBlank && !startNaN) {
                        error.overlap = 'Chapter times cannot overlap';
                        error.conflicts = conflicts;
                    }
                }
            }
            // Start cannot be blank
            if (startBlank) {
                error.start = isAnnotation ? 'Start time is required.' : 'Time is required.';
            }
            // Letters not allowed
            if (startNaN) {
                error.start = 'Start time must be numbers.';
            }
            // end time cannot be less than start time

            timespanErrors.push(error);
        });

        return timespanErrors;
    };

    validateArrayFields = (array, type, lang) => {
        const arrayErrors = [];

        if (type === 'audio') {
            array.forEach((item, index) => {
                const error = {};
                const { language, name, track } = item;

                if (!language) {
                    error.language = 'Language is required.';
                }
                if (!name) {
                    error.name = 'Name is required.';
                }
                if (_isEmpty(track)) {
                    error.track = 'Audio file is required.';
                }

                arrayErrors.push(error);
            });

            return arrayErrors;
        }
        // chapters / annotations (timespan validation)
        const timespanErrors = this.validateTimespans(array, type);
        const languages = this.getLanguageArr(lang);

        array.forEach((item, index) => {
            const error = { ...timespanErrors[index] };

            if (type === 'annotations') {
                const { content } = item;
                const blankContent = languages.filter((key, id) => !content[key]);

                if (blankContent.length > 0) {
                    error.content = {};

                    blankContent.forEach(thing => {
                        error.content[thing] = 'Content is required';
                    });
                }
            }

            if (type === 'chapters') {
                const { label } = item;
                const blankLabels = languages.filter((key, id) => !label[key]);

                if (blankLabels.length > 0) {
                    error.label = {};

                    blankLabels.forEach(thing => {
                        error.label[thing] = 'Label is required';
                    });
                }
            }

            arrayErrors.push(error);
        });

        return arrayErrors;
    };

    validate = values => {
        this.setState({
            currentVersion:
                values.currentVersion === 'Use most recent' ? values.currentVersion : +values.currentVersion,
            currentValues: values,
        });

        const errors = {};

        if (values.annotations.length > 0) {
            const annotationErrors = this.validateArrayFields(values.annotations, 'annotations', values.languages);
            const hasAnnotationErrors = annotationErrors.some(
                ({ start, end, overlap, content }) => start || end || overlap || content
            );

            if (hasAnnotationErrors) {
                notification({
                    type: 'warning',
                    message: 'There are validation errors. Please check Annotations.',
                });
                errors.annotations = annotationErrors;
            }
        }
        if (values.chapters.length > 0) {
            const chapterErrors = this.validateArrayFields(values.chapters, 'chapters', values.languages);
            const hasChapterErrors = chapterErrors.some(
                ({ start, end, overlap, label }) => start || end || overlap || label
            );

            if (hasChapterErrors) {
                notification({
                    type: 'warning',
                    message: 'There are validation errors. Please check Chapters.',
                });
                errors.chapters = chapterErrors;
            }
        }
        if (values.audio.length > 0) {
            const audioErrors = this.validateArrayFields(values.audio, 'audio');
            const hasAudioErrors = audioErrors.some(({ language, name, track }) => language || name || track);

            if (hasAudioErrors) {
                notification({
                    type: 'warning',
                    message: 'There are validation errors. Please check Audio Tracks.',
                });
                errors.audio = audioErrors;
            }
        }

        return errors;
    };

    render() {
        const { loading, initialValues, versionData } = this.state;

        const versionList = [{ value: null, label: 'Use most recent' }];
        if (versionData && versionData.versions) {
            versionData.versions.forEach(item => {
                if (item.status === 'COMPLETED') {
                    versionList.push({
                        value: +item.version_number,
                        label: ` Always use version ${item.version_number.toString().padStart(3, '0')}`,
                    });
                }
            });
        }

        if (loading) {
            return <Loader loading />;
        }

        return (
            <>
                <div className={style.replaceClose}>
                    <button className="close" type="button" onClick={this.confirmClose}>
                        <Cross />
                    </button>
                </div>
                <Formik
                    initialValues={initialValues}
                    validate={this.validate}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={this.onSubmit}
                    render={({ values, errors }) => {
                        const initialVals = _omit(initialValues, 'accordianState');

                        const currentVals = _omit(values, 'accordianState');
                        const hasChanges = !_isEqual(initialVals, currentVals);
                        const useMostRecent = values.currentVersion === 'Use most recent';
                        const latestCompleted =
                            versionData &&
                            versionData.versions &&
                            versionData.versions
                                .slice()
                                .reverse()
                                .find(item => item.status === 'COMPLETED');

                        const selectedVersion = useMostRecent
                            ? latestCompleted
                            : versionData &&
                              versionData.versions &&
                              versionData.versions.find(ver => +ver.version_number === +values.currentVersion);

                        const videoProps =
                            selectedVersion &&
                            selectedVersion.status === 'COMPLETED' &&
                            this.getPlayerProps(values, selectedVersion.version_number);

                        return (
                            <Form>
                                <div className={style.versionSelect}>
                                    {versionData ? (
                                        <>
                                            <h4>Current Version:</h4>
                                            <div className={style.previewRow}>
                                                <Field
                                                    name="currentVersion"
                                                    component={CccisdSelect}
                                                    options={versionList}
                                                />
                                                {videoProps && (
                                                    <Modal
                                                        trigger={
                                                            <button type="button" className="btn btn-primary">
                                                                <Tooltip
                                                                    title="Preview Video"
                                                                    size="medium"
                                                                    placement="top"
                                                                >
                                                                    <Play /> Preview
                                                                </Tooltip>
                                                            </button>
                                                        }
                                                        size="large"
                                                        title={`Preview Video Settings - ${this.props.row['fields.label']}`}
                                                    >
                                                        <Player {...videoProps} />
                                                    </Modal>
                                                )}
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                                {this.props.row['fields.note'] && (
                                    <div>
                                        <b>Video Notes:</b>
                                        <p>{this.props.row['fields.note']}</p>
                                    </div>
                                )}
                                <Accordian
                                    title="Versions"
                                    startOpen={values.accordianState.versions}
                                    type="versions"
                                    updateState={this.updateOpenState}
                                >
                                    {this.renderVersions(values, values.currentVersion)}
                                </Accordian>
                                <Accordian
                                    title="Poster Image"
                                    startOpen={values.accordianState.image}
                                    type="image"
                                    updateState={this.updateOpenState}
                                >
                                    {this.renderPosterImage()}
                                </Accordian>
                                <Accordian
                                    title="Audio Tracks"
                                    startOpen={values.accordianState.audio}
                                    type="audio"
                                    updateState={this.updateOpenState}
                                    context={errors.audio && 'warning'}
                                >
                                    {this.renderArrayFields('audiotrack')}
                                </Accordian>
                                <Accordian
                                    title="Languages"
                                    startOpen={values.accordianState.languages}
                                    type="languages"
                                    updateState={this.updateOpenState}
                                >
                                    {this.renderLanguageSelection()}
                                </Accordian>
                                <Accordian
                                    title="Chapters"
                                    startOpen={values.accordianState.chapters}
                                    type="chapters"
                                    updateState={this.updateOpenState}
                                    context={errors.chapters && 'warning'}
                                >
                                    {this.renderArrayFields('chapter')}
                                </Accordian>
                                <Accordian
                                    title="Annotations"
                                    startOpen={values.accordianState.annotations}
                                    type="annotations"
                                    updateState={this.updateOpenState}
                                    context={errors.annotations && 'warning'}
                                >
                                    {this.renderArrayFields('annotation')}
                                </Accordian>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <button className="btn btn-primary" type="button" onClick={this.confirmClose}>
                                        Cancel
                                    </button>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        style={
                                            hasChanges
                                                ? {
                                                      backgroundColor: '#F0AD4E',
                                                      borderColor: '#fff',
                                                  }
                                                : {}
                                        }
                                    >
                                        Submit
                                    </button>
                                </div>
                            </Form>
                        );
                    }}
                />
            </>
        );
    }
}
