import React from 'react';
import { ProducerPlayer } from 'cccisd-video-player';
import PropTypes from 'prop-types';

const Player = props => {
    const { sources, posters, chapters, annotations, image } = props;
    const options = {
        autoplay: false,
        loop: false,
        controls: true,
        volume: 1,
        sources,
        posters,
        chapters,
        annotations,
        image,
    };

    return (
        <ProducerPlayer
            autoplay={options.autoplay}
            loop={options.loop}
            controls={options.controls}
            volume={options.volume}
            posters={options.posters}
            sources={options.sources}
            chapters={options.chapters}
            annotations={options.annotations}
            posterUrl={options.image.url}
        />
    );
};

Player.propTypes = {
    sources: PropTypes.array,
    posters: PropTypes.array,
    chapters: PropTypes.array,
    annotations: PropTypes.array,
    image: PropTypes.object,
};

Player.defaultProps = {};

export default Player;
