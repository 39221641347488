import React from 'react';
import videojs from 'video.js';
import Modal from 'cccisd-modal';
import 'video.js/dist/video-js.css';
import VideoPlayer from './Player.js';
import './style.css';
import CameraIcon from 'cccisd-icons/camera';

export const Snapshoter = props => {
    const playerRef = React.useRef(null);
    const modal = React.useRef();
    const videoJsOptions = {
        autoplay: false,
        controls: true,
        controlBar: {
            pictureInPictureToggle: false,
            fullscreenToggle: false,
        },
        responsive: true,
        fluid: true,
        sources: [
            {
                src: props.videoUrl,
                type: 'application/x-mpegURL',
            },
        ],
    };

    const handlePlayerReady = player => {
        playerRef.current = player;

        // You can handle player events here, for example:
        player.on('waiting', () => {
            videojs.log('player is waiting');
        });

        player.on('dispose', () => {
            videojs.log('player will dispose');
        });
    };
    const closeModal = () => {
        modal.current.close();
    };
    return (
        <Modal
            trigger={
                <button type="button" className="btn btn-primary">
                    Take Snapshot <CameraIcon spaceLeft />
                </button>
            }
            title="Take A Video Snapshot"
            size="medium"
            ref={modal}
        >
            <div className="modalBody">
                <div className="instructions">
                    Play the video and click the <CameraIcon /> button to take a snapshot.
                </div>
                <VideoPlayer options={videoJsOptions} onReady={handlePlayerReady} closeModal={closeModal} {...props} />
            </div>
        </Modal>
    );
};
