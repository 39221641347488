import React, { useRef, useEffect, useState } from 'react';
import videojs from 'video.js';
import ImageDisplay from './ImageDisplay';
import style from './style.css';
import axios from 'cccisd-axios';
import notification from 'cccisd-notification';
import Loader from 'cccisd-loader';
const Boilerplate = window.cccisd.boilerplate;

const VideoPlayer = props => {
    const { options, onReady, row, closeModal, form } = props;

    const videoRef = useRef(null);
    const playerRef = useRef(null);

    const [snapshot, setSnapshot] = useState(null);
    const [isSaved, setIsSaved] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const captureSnapshot = () => {
        try {
            const videoDiv = videoRef.current;

            const videoElement = videoDiv.firstChild.firstChild;
            // Create a canvas element to draw the video frame
            const canvas = document.createElement('canvas');
            canvas.width = videoElement.videoWidth;
            canvas.height = videoElement.videoHeight;
            const ctx = canvas.getContext('2d');

            // Draw the current video frame onto the canvas
            ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

            // Get the image data from the canvas as a data URL
            canvas.toBlob(blob => {
                setSnapshot(blob);
            }, 'image/png');

            // Use the data URL as needed (e.g., save it, display it, etc.)
        } catch (e) {
            console.error(e);
        }
    };

    const saveSnapshot = async () => {
        const videoLabel = row['fields.label'].replace(/[\W]+/g, '_');

        const pngFile = new File([snapshot], `${videoLabel}_Snapshot.png`, { type: 'image/png' });
        const formData = new FormData();
        formData.append('path', '/');
        formData.append('file', pngFile);

        try {
            setIsLoading(true);
            const response = await axios.post(Boilerplate.route('api.resources.file.store'), formData);

            if (response.data && response.data.data && response.data.data.files) {
                const url = response.data.data.files[0].published_url;
                form.setFieldValue('image', { url });

                setIsLoading(false);
                setIsSaved(true);

                notification({ message: 'Snapshot Saved', type: 'success' });

                closeModal();
            }
        } catch (error) {
            setIsLoading(false);
            console.error(error);
            notification({ message: 'Failed to save snapshot', type: 'danger' });
        }
    };

    useEffect(() => {
        // Make sure Video.js player is only initialized once
        if (!playerRef.current) {
            // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
            const videoElement = document.createElement('video-js');

            videoElement.classList.add('vjs-big-play-centered');
            videoRef.current.appendChild(videoElement);

            // eslint-disable-next-line no-multi-assign
            const player = (playerRef.current = videojs(videoElement, options, () => {
                videojs.log('player is ready');
                onReady && onReady(player);
            }));
            // make new button

            const Button = videojs.getComponent('Button');
            const button = new Button(player, {
                clickHandler: event => {
                    videojs.log('Clicked');
                    captureSnapshot();
                },
                controlText: 'Snapshot',
                className: 'camera-icon',
            });

            const controlBar = player.controlBar;
            controlBar.addChild(button);

            // gross but only way i could get icon into control
            document.querySelector('.camera-icon .vjs-icon-placeholder').innerHTML =
                '<svg class="_3hZAm" viewBox="0 0 1024 1024" width="16px" height="16px"><g><path fill="#fff" d="M304 608c0 114.876 93.124 208 208 208s208-93.124 208-208-93.124-208-208-208-208 93.124-208 208zM960 256h-224c-16-64-32-128-96-128h-256c-64 0-80 64-96 128h-224c-35.2 0-64 28.8-64 64v576c0 35.2 28.8 64 64 64h896c35.2 0 64-28.8 64-64v-576c0-35.2-28.8-64-64-64zM512 892c-156.85 0-284-127.148-284-284 0-156.85 127.15-284 284-284 156.852 0 284 127.15 284 284 0 156.852-127.146 284-284 284zM960 448h-128v-64h128v64z"></path></g></svg>';
        } else {
            const player = playerRef.current;
            const controlBar = player.controlBar;
            const Button = videojs.getComponent('Button');
            const button = new Button(player, {
                clickHandler: event => {
                    videojs.log('Clicked');
                    captureSnapshot();
                },
                className: 'camera-icon',
            });

            controlBar.addChild(button);
            player.autoplay(options.autoplay);
            player.src(options.sources);
        }
    }, [options, videoRef]);

    useEffect(() => {
        setIsSaved(false);
    }, [snapshot]);

    if (isLoading) {
        return <Loader loading />;
    }
    return (
        <div className={style.playerWrapper}>
            <div data-vjs-player>
                <div ref={videoRef} />
                {snapshot && (
                    <>
                        <ImageDisplay blob={snapshot} />
                        <div className={style.buttonBar}>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                    closeModal();
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                style={
                                    isSaved
                                        ? {}
                                        : {
                                              backgroundColor: '#F0AD4E',
                                              borderColor: '#fff',
                                          }
                                }
                                onClick={saveSnapshot}
                                disabled={isSaved}
                            >
                                Save Snapshot
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default VideoPlayer;
