import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'cccisd-modal';
import { AssetPicker } from 'cccisd-laravel-resources';

const AudioPicker = props => {
    const { trigger, onChange } = props;
    const value = typeof props.value === 'object' ? props.value.id : props.value;

    return (
        <Modal
            title="Select Audio Track"
            size="large"
            trigger={trigger}
            render={({ closeModal }) => (
                <AssetPicker
                    globalFilter="audio"
                    render={({ body, selectedFile }) => (
                        <div>
                            {body}
                            <div style={{ marginTop: '1em' }}>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled={!selectedFile}
                                    onClick={() => {
                                        onChange(selectedFile);
                                        closeModal();
                                    }}
                                >
                                    Select Audio
                                </button>
                            </div>
                        </div>
                    )}
                    {...(value && { initialSelectedFile: value })}
                />
            )}
        />
    );
};

AudioPicker.propTypes = {
    trigger: PropTypes.node,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    onChange: PropTypes.func,
};

AudioPicker.defaultProps = {
    trigger: (
        <button type="button" className="btn btn-default">
            Select Audio Track
        </button>
    ),
};

export default AudioPicker;
