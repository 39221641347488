import React, { useEffect } from 'react';
import style from './style.css';
const ImageDisplay = ({ blob }) => {
    useEffect(() => {
        const imageUrl = URL.createObjectURL(blob);

        return () => {
            URL.revokeObjectURL(imageUrl);
        };
    }, [blob]);

    return (
        <>
            <hr />
            <h5 style={{ textAlign: 'center' }}>Current Snapshot:</h5>
            <div className={style.imageDisplay}>
                <img style={{ width: '75%' }} src={URL.createObjectURL(blob)} alt="Blob" />
            </div>
        </>
    );
};

export default ImageDisplay;
