import React from 'react';

export default function Filters({ settings }) {
    function setRequiredVariables(filter) {
        settings.setRequiredVariables(prev => {
            let andArr = prev.length > 0 ? prev.find(p => p.variable === 'filter').value.AND : [];
            if (filter.eq && filter.eq.field === 'ancestorGroups.organization.group.label') {
                andArr = [];
            } else if (filter.eq && filter.eq.field === 'ancestorGroups.groupingUnit.group.label') {
                andArr = andArr.filter(f => f.eq.field !== 'parentGroup.site.group.label');
            }
            if ((filter.eq && !filter.eq.string) || (filter.contains && !filter.contains.string)) {
                if (andArr.length <= 1) {
                    return [];
                }
                return [
                    {
                        variable: 'filter',
                        value: {
                            AND: andArr.filter(f => {
                                if (filter.eq && f.eq) {
                                    return f.eq.field !== filter.eq.field;
                                }
                                if (filter.contains && f.contains) {
                                    return f.contains.field !== filter.contains.field;
                                }
                                return true;
                            }),
                        },
                    },
                ];
            }
            if (
                andArr.some(f => {
                    if (filter.eq && f.eq) {
                        return f.eq.field === filter.eq.field;
                    }
                    if (filter.contains && f.contains) {
                        return f.contains.field === filter.contains.field;
                    }
                    return false;
                })
            ) {
                return [
                    {
                        variable: 'filter',
                        value: {
                            AND: andArr.map(f => {
                                if (filter.eq && f.eq && f.eq.field === filter.eq.field) {
                                    return filter;
                                }
                                if (
                                    filter.contains &&
                                    f.contains &&
                                    f.contains.field === filter.contains.field
                                ) {
                                    return filter;
                                }
                                return f;
                            }),
                        },
                    },
                ];
            }
            return [{ variable: 'filter', value: { AND: [...andArr, filter] } }];
        });
    }

    return (
        <div
            style={{
                display: 'flex',
                gap: '1em',
                alignItems: 'baseline',
                backgroundColor: '#eee',
                borderRadius: '10px',
                padding: '1em',
                marginTop: '1em',
                width: '100%',
            }}
        >
            {settings.projectList.length > 1 && (
                <div className="form-group" style={{ margin: '0', width: '15%' }}>
                    <label>Project:</label>
                    <select
                        value={settings.project}
                        className="form-control"
                        onChange={v => {
                            setRequiredVariables({
                                eq: {
                                    field: 'ancestorGroups.organization.group.label',
                                    string: v.target.value,
                                },
                            });
                            settings.setProject(v.target.value);
                            settings.setModule('');
                            settings.setLesson('');
                            settings.setAutoFocus('');
                        }}
                    >
                        {[
                            { label: 'All Projects', value: '' },
                            ...settings.projectList.map(p => {
                                return { label: p.group.label, value: p.group.label };
                            }),
                        ].map(o => (
                            <option value={o.value}>{o.label}</option>
                        ))}
                    </select>
                </div>
            )}
            <div className="form-group" style={{ margin: '0', width: '15%' }}>
                <label>Module:</label>
                <select
                    value={settings.module}
                    className="form-control"
                    onChange={v => {
                        setRequiredVariables({
                            eq: {
                                field: 'ancestorGroups.groupingUnit.group.label',
                                string: v.target.value,
                            },
                        });
                        settings.setModule(v.target.value);
                        settings.setLesson('');
                        settings.setAutoFocus('');
                    }}
                >
                    {[
                        { label: 'All Modules', value: '' },
                        ...settings.moduleList.map(m => {
                            return { label: m.group.label, value: m.group.label };
                        }),
                    ].map(o => (
                        <option value={o.value}>{o.label}</option>
                    ))}
                </select>
            </div>
            <div className="form-group" style={{ margin: '0', width: '15%' }}>
                <label>Lesson:</label>
                <select
                    value={settings.lesson}
                    className="form-control"
                    onChange={v => {
                        setRequiredVariables({
                            eq: { field: 'parentGroup.site.group.label', string: v.target.value },
                        });
                        settings.setLesson(v.target.value);
                        settings.setAutoFocus('');
                    }}
                >
                    {[
                        { label: 'All Lessons', value: '' },
                        ...settings.lessonList.map(l => {
                            return { label: l.group.label, value: l.group.label };
                        }),
                    ].map(o => (
                        <option value={o.value}>{o.label}</option>
                    ))}
                </select>
            </div>
            <div className="form-group" style={{ margin: '0', width: '15%' }}>
                <label>Name:</label>
                <input
                    autoFocus={settings.autoFocus === 'videoName'}
                    type="text"
                    value={settings.videoName}
                    className="form-control"
                    onChange={v => {
                        setRequiredVariables({
                            contains: { field: 'fields.label', string: v.target.value },
                        });
                        settings.setVideoName(v.target.value);
                        settings.setAutoFocus('videoName');
                    }}
                />
            </div>
            <div className="form-group" style={{ margin: '0', width: '10%' }}>
                <label>Clip ID:</label>
                <input
                    autoFocus={settings.autoFocus === 'clipId'}
                    type="text"
                    value={settings.clipId}
                    className="form-control"
                    onChange={v => {
                        setRequiredVariables({
                            eq: { field: 'pawn.pawnId', string: v.target.value },
                        });
                        settings.setClipId(v.target.value);
                        settings.setAutoFocus('clipId');
                    }}
                />
            </div>
            <div
                className="form-group"
                style={{
                    alignSelf: 'flex-end',
                    margin: '0',
                    visibility:
                        settings.project ||
                        settings.module ||
                        settings.lesson ||
                        settings.videoName ||
                        settings.clipId
                            ? 'visible'
                            : 'hidden',
                }}
            >
                <button
                    className="btn btn-sm btn-primary"
                    type="button"
                    onClick={() => {
                        settings.setProject('');
                        settings.setModule('');
                        settings.setLesson('');
                        settings.setVideoName('');
                        settings.setClipId('');
                        settings.setRequiredVariables([]);
                    }}
                >
                    Clear Filters
                </button>
            </div>
        </div>
    );
}
