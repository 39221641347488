import React, { useState } from 'react';
import Modal from 'cccisd-modal';
import notification from 'cccisd-notification';
import Tooltip from 'cccisd-tooltip';
import IconCopy from 'cccisd-icons/copy2';

const CopyEmbed = ({ row }) => {
    const ref = React.createRef();
    const [code, setCode] = useState(
        `<iframe width="560" height="315" src="https://public.3c-cdn.com/video/index.html?id=${row['pawn.pawnId']}" title="Producer Video Player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
    );

    return (
        <Modal
            trigger={
                <button type="button" className="btn btn-xs btn-primary">
                    <Tooltip title="Embed Code" size="medium" placement="bottom">
                        <IconCopy />
                    </Tooltip>
                </button>
            }
            ref={ref}
            size="large"
            title={`Copy Embed Code - ${row['fields.label']}`}
        >
            <textarea style={{ width: '100%' }} value={code} onChange={e => setCode(e.target.value)} rows="10" />
            <div style={{ display: 'flex', width: '100%', justifyContent: 'right' }}>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                        navigator.clipboard.writeText(code);
                        notification('Embed Code copied!');
                        ref.current.close();
                    }}
                >
                    Copy Embed Code
                </button>
            </div>
        </Modal>
    );
};

export default CopyEmbed;
