import React from 'react';

const Subscribers = ({ value }) => {
    if (!value) {
        value = '[]';
    }
    const subscribersArray = JSON.parse(value);
    return subscribersArray.length;
};

export default settings => {
    return props => <Subscribers {...props} settings={settings} />;
};
