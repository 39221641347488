import React from 'react';
import Modal from 'cccisd-modal';
import Tooltip from 'cccisd-tooltip';
import IconCC from 'cccisd-icons/cc';
import CaptionSettings from '../CaptionSettings';

const ConfigureVideo = ({ row, loadData }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Modal
                trigger={
                    <button type="button" className="btn btn-xs btn-primary">
                        <Tooltip title="Configure Captions" size="medium" placement="bottom">
                            <IconCC />
                        </Tooltip>
                    </button>
                }
                size="large"
                title={
                    <span>
                        <IconCC spaceRight />
                        {`Captions - ${row['fields.label']}`}
                    </span>
                }
                render={({ closeModal }) => (
                    <CaptionSettings closeModal={closeModal} row={row} loadData={loadData} />
                )}
            />
        </div>
    );
};

export default ConfigureVideo;
