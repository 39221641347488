import React from 'react';
import PropTypes from 'prop-types';
import Header from 'cccisd-header';
import style from './style.css';

const NavBar = ({ className }) => {
    return (
        <div className={`${style.navbar}`}>
            <Header
                className={className}
                logo={
                    <div className={`${style.helper}`}>
                        <img className={`${style.helper}`} src="/images/producer_bullhorn.png" alt="Producer" />
                    </div>
                }
            />
        </div>
    );
};

NavBar.defaultProps = {
    className: 'container',
};

NavBar.propTypes = {
    className: PropTypes.string,
};

export default NavBar;
